.dual-blocks {
  overflow: hidden;
  --gap: 0px;
  padding-bottom: 50px;
}
@media (max-width: 991px) {
  .dual-blocks {
    --gap: 30px;
    padding-bottom: 30px;
  }
}
.dual-blocks.container {
  padding-bottom: 30px;
}
.dual-blocks.dual-blocks--center-padding {
  --gap: 20px;
}
.dual-blocks .dual-blocks__container {
  display: flex;
  gap: var(--gap);
}
@media (max-width: 991px) {
  .dual-blocks .dual-blocks__container {
    flex-wrap: wrap;
  }
}
.dual-blocks .dual-blocks__image {
  position: relative;
  padding-top: 100%;
}
.dual-blocks .dual-blocks__wrapper {
  width: 50%;
}
@media (max-width: 991px) {
  .dual-blocks .dual-blocks__wrapper {
    width: 100%;
  }
}
.dual-blocks .dual-blocks__wrapper.dual-blocks__wrapper--full {
  width: 100%;
}
.dual-blocks .dual-blocks__wrapper.dual-blocks__wrapper--full .dual-blocks__image {
  padding-top: 56.25%;
}
@media (max-width: 991px) {
  .dual-blocks .dual-blocks__wrapper.dual-blocks__wrapper--full .dual-blocks__image {
    padding-top: 75.25%;
  }
}
@media (max-width: 575px) {
  .dual-blocks .dual-blocks__wrapper.dual-blocks__wrapper--full .dual-blocks__image {
    padding-top: 100%;
  }
}
.dual-blocks .dual-blocks__inner::after,
.dual-blocks .dual-blocks__image img,
.dual-blocks .dual-blocks__content-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.dual-blocks .dual-blocks__inner {
  position: relative;
  overflow: hidden;
}
.dual-blocks .dual-blocks__inner::after {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 67.29%, rgba(0, 0, 0, 0.35) 100%);
}
.dual-blocks .dual-blocks__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dual-blocks .dual-blocks__content-wrapper {
  z-index: 1;
  display: flex;
  padding: 101px 105px;
}
@media (max-width: 1500px) {
  .dual-blocks .dual-blocks__content-wrapper {
    padding: 90px;
  }
}
@media (max-width: 1199px) {
  .dual-blocks .dual-blocks__content-wrapper {
    padding: 70px;
  }
}
@media (max-width: 991px) {
  .dual-blocks .dual-blocks__content-wrapper {
    padding: 50px;
  }
}
@media (max-width: 575px) {
  .dual-blocks .dual-blocks__content-wrapper {
    padding: 40px;
  }
}
@media (max-width: 480px) {
  .dual-blocks .dual-blocks__content-wrapper {
    padding: 20px 15px;
  }
}
.dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--top-left {
  align-items: flex-start;
  text-align: left;
}
.dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--top-middle {
  align-items: flex-start;
  text-align: center;
}
.dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--top-right {
  align-items: flex-start;
  text-align: right;
}
.dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--center-left {
  align-items: center;
  text-align: left;
}
.dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--center-center {
  align-items: center;
  text-align: center;
}
.dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--center-right {
  align-items: center;
  text-align: right;
}
.dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--bottom-left {
  align-items: flex-end;
  text-align: left;
}
.dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--bottom-center {
  align-items: flex-end;
  text-align: center;
}
.dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--bottom-right {
  align-items: flex-end;
  text-align: right;
}
@media (max-width: 575px) {
  .dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--mobile-top-left {
    align-items: flex-start;
    text-align: left;
  }
  .dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--mobile-top-middle {
    align-items: flex-start;
    text-align: center;
  }
  .dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--mobile-top-right {
    align-items: flex-start;
    text-align: right;
  }
  .dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--mobile-center-left {
    align-items: center;
    text-align: left;
  }
  .dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--mobile-center-center {
    align-items: center;
    text-align: center;
  }
  .dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--mobile-center-right {
    align-items: center;
    text-align: right;
  }
  .dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--mobile-bottom-left {
    align-items: flex-end;
    text-align: left;
  }
  .dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--mobile-bottom-center {
    align-items: flex-end;
    text-align: center;
  }
  .dual-blocks .dual-blocks__content-wrapper.dual-blocks__content-wrapper--mobile-bottom-right {
    align-items: flex-end;
    text-align: right;
  }
}
.dual-blocks .dual-blocks__content {
  width: 100%;
  height: max-content;
}
.dual-blocks .dual-blocks__heading {
  margin-bottom: 7px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.125;
  color: #ffffff;
}
@media (max-width: 1199px) {
  .dual-blocks .dual-blocks__heading {
    margin-bottom: 9px;
    font-size: 35px;
  }
}
@media (max-width: 575px) {
  .dual-blocks .dual-blocks__heading {
    margin-bottom: 13px;
    font-size: 25px;
  }
}
.dual-blocks .dual-blocks__text {
  margin-bottom: 21px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.222;
  letter-spacing: 0.08em;
  color: #ffffff;
}
@media (max-width: 1199px) {
  .dual-blocks .dual-blocks__text {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .dual-blocks .dual-blocks__text {
    font-size: 13px;
  }
}
.dual-blocks .dual-blocks__link {
  text-decoration: none;
}
@media (max-width: 575px) {
  .dual-blocks .dual-blocks__link {
    min-width: auto;
  }
}
.dual-blocks .dual-blocks__link:focus-visible {
  border-color: #ffffff;
  background-color: #ffffff;
}
/*Next code from "_blocks/_product-list/product-list" file, but there it not work*/